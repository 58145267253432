import styled from '@emotion/styled'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import IcoArrowRight from '@/assets/images/ico_arrow_right.svg?url'
import SkeletonItem from '@/components/loading/SkeletonItem'
import { ROUTER } from '@/constants/router'
import useFaqCategory from '@/hooks/useFaqCategory'
import { sendEvent } from '@/libs/mixpanel'
import { COLOR } from '@/styles/color'
import { touchGuide } from '@/styles/mixin'
import { movePage } from '@/utils/move'

import Section from './shared/components/Section'

function CategorySection() {
  const router = useRouter()
  const { categories, isLoading } = useFaqCategory()

  const handleClickCategoryButton = useCallback((categoryName: string) => () => {
    sendEvent(ROUTER.MAIN, 'tap.category', { category_name: categoryName })
    const query = {
      categoryName,
    }

    if (TmapApp.env.isInApp) {
      movePage({ pathname: ROUTER.CATEGORY, query })
    } else {
      router.push({
        pathname: ROUTER.CATEGORY,
        query,
      })
    }
  }, [router])

  return (
    <Section
      title="카테고리로 찾아보세요."
      fullWidth
    >
      {isLoading ? <Skeleton /> : (
        <List>
          {categories?.map(({ id, name }) => (
            <li key={id}>
              <Button
                type="button"
                onClick={handleClickCategoryButton(name)}
              >{name}
              </Button>
            </li>
          ))}
        </List>
      )}
    </Section>
  )
}

function Skeleton({ size = 14 }: { size?: number }) {
  return (
    <SkeletonWrapper>
      {[...Array(size)].map((_, index) => (
        <SkeletonItem
          key={`CategorySection-SkeletonItem${index}`}
          width="calc(50% - 4px)"
          height={32}
          borderRadius="4px"
        />
      ))}
    </SkeletonWrapper>
  )
}

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;

  & > li {
    width: 50%;
  }
`

const Button = styled.button`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 16px 20px;
  width: 100%;
  color: ${COLOR.gray.color.gray[900]};
  text-align: left;

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(${IcoArrowRight}) no-repeat center center;
  }

  ${touchGuide()}
`

const SkeletonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 27px 8px;
  padding: 4px 20px 0;
`

export default CategorySection
