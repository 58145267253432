import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { COLOR } from '@/styles/color'
import { fontSize } from '@/styles/mixin'

interface SectionProps {
  fullWidth?: boolean
  title?: React.ReactNode
  children: React.ReactNode
}

function Section({ fullWidth, title, children }: SectionProps) {
  return (
    <Container fullWidth={fullWidth}>
      {title && <SubTitle fullWidth={fullWidth}>{title}</SubTitle>}
      {children}
    </Container>
  )
}

const Container = styled.section<{ fullWidth?: boolean }>`
  padding: ${({ fullWidth }) => (fullWidth ? '32px 0' : '32px 20px')};
  background-color: ${COLOR.gray.color.wb[0]};
`

const SubTitle = styled.h3<{ fullWidth?: boolean }>`
  margin-bottom: 16px;
  ${({ theme }) => fontSize(theme, 18)};
  font-weight: var(--font-weight-bold);
  color: ${COLOR.gray.color.gray[900]};

  ${({ fullWidth }) => fullWidth && css`
    padding: 0 20px;
  `}
`

export default Section
