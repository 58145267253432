import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useCallback } from 'react'

import SkeletonItem from '@/components/loading/SkeletonItem'
import { COLOR } from '@/styles/color'
import { fontSize, touchGuide } from '@/styles/mixin'

type Keyword = {
  name: string
  value: string | boolean
}

type Align = 'left' | 'center' | 'right' | 'start' | 'end'

interface KeywordButtonsProps {
  keywords?: Keyword[]
  isLoading?: boolean
  loadingItemSize?: number
  align?: Align
  onClick?: (value: string | boolean) => void
}

function KeywordButtons({
  keywords = [], isLoading, loadingItemSize, align = 'left', onClick,
}: KeywordButtonsProps) {
  const handleClickKeyword = useCallback((value: string | boolean) => {
    onClick?.(value)
  }, [onClick])

  return (
    <Container align={align}>
      {isLoading ? <Skeleton size={loadingItemSize} /> : keywords.map(({ name, value }) => (
        <KeywordButton
          key={value.toString()}
          onClick={() => handleClickKeyword(value)}
        >
          {name}
        </KeywordButton>
      ))}
    </Container>
  )
}

function Skeleton({ size = 4 }: { size?: number }) {
  return (
    <>
      {[...Array(size)].map((_, index) => (
        <SkeletonItem
          key={`KeywordButton-Skeleton${index}`}
          width={100}
          height={32}
          borderRadius="50px"
        />
      ))}
    </>
  )
}

const Container = styled.div<{ align: Align }>`
  display: flex;
  gap: 12px 8px;
  flex-wrap: wrap;
  
  ${({ align }) => {
    switch (align) {
      case 'left':
      case 'start':
        return css`justify-content: flex-start;`
      case 'center':
        return css`justify-content: center;`
      case 'right':
      case 'end':
        return css`justify-content: flex-end;`
      default:
        return css``
    }
  }}
`

const KeywordButton = styled.button`
  position: relative;
  padding: 6px 12px;
  background-color: ${COLOR.gray.color.gray[200]};
  border-radius: 50px;
  color: ${COLOR.gray.color.gray[900]};
  ${({ theme }) => fontSize(theme, 14)}
  ${touchGuide({ borderRadius: '16px' })}
`

export default KeywordButtons
