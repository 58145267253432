import styled from '@emotion/styled'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { ArticleItem } from '@/apis/frontman/helpcenter/types'
import { ROUTER } from '@/constants/router'
import useFaqCategory from '@/hooks/useFaqCategory'
import { COLOR } from '@/styles/color'
import { fontSize, touchGuide } from '@/styles/mixin'
import { movePage } from '@/utils/move'

export type ListData = {
  id: number
  title: string
  path: string
}

interface ArticleListProps {
  data?: ArticleItem[]
  visibleCategory?: boolean
  onClickArticle?: (article: ArticleItem) => void
}

function ArticleList({ data, visibleCategory = false, onClickArticle }: ArticleListProps) {
  const router = useRouter()
  const { getCategoryNames } = useFaqCategory()

  const handleClickArticle = useCallback((article: ArticleItem) => () => {
    const { id } = article
    if (id) {
      if (TmapApp.env.isInApp) {
        movePage({ pathname: ROUTER.FAQ(id) })
      } else {
        router.push(ROUTER.FAQ(id))
      }
    }
    onClickArticle?.(article)
  }, [onClickArticle, router])

  return (
    <List>
      {data?.map((article) => {
        const { id, title, section_id } = article
        return (
          <li key={id}>
            <SelectionLink
              type="button"
              onClick={handleClickArticle(article)}
            >
              {(visibleCategory && section_id) && (
                <CategoryText>{getCategoryNames(section_id).join('  >  ')}</CategoryText>
              )}
              {title}
            </SelectionLink>
          </li>
        )
      })}
    </List>
  )
}

const List = styled.ul`

  & > li {
    position: relative;

    &:nth-of-type(n+2) {
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: calc(100% - 40px);
        height: 1px;
        background-color: ${COLOR.gray.color.gray[200]};
      }
    }
  }
`

const SelectionLink = styled.button`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 16px 20px;
  text-align: left;

  ${touchGuide()}
`

const CategoryText = styled.div`
  color: ${COLOR.gray.color.gray[500]};
  ${({ theme }) => fontSize(theme, 12)}
`

export default ArticleList
