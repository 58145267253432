export const enum REQUESTS_STATUS {
  NEW = 'new',
  OPEN = 'open',
  PENDING = 'pending',
  HOLD = 'hold',
  SOLVED = 'solved',
  CLOSED = 'closed',
}

export const enum REQUESTS_PRIORITY {
  LOW = 'low',
  NORMAL = 'normal',
  HIGH = 'high',
  URGENT = 'urgent',
}

export type Comment = {
  body: string
  uploads: string[]
}

export const enum COMMENT_TYPE {
  COMMENT = 'Comment',
  VOICE_COMMENT = 'VoiceComment',
}

// https://developer.zendesk.com/api-reference/ticketing/tickets/ticket-attachments/
export type Attachment = {
  content_type?: string
  content_url?: string
  deleted?: boolean
  file_name?: string
  height?: string
  id?: number
  inline?: boolean
  malware_access_override?: boolean
  malware_scan_result?: string
  mapped_content_url?: string
  size?: number
  thumbnails: any[]
  url?: string
  width?: string
}

// https://developer.zendesk.com/api-reference/ticketing/tickets/ticket_comments/#json-format
export type TicketComment = {
  attachments?: Attachment[]
  audit_id?: number
  author_id?: number
  body?: string
  created_at?: string
  html_body?: string
  id?: number
  metadata?: Record<any, any>
  plain_body?: string
  public?: boolean
  type?: COMMENT_TYPE
  uploads?: string[]
  via?: Via
}

// https://developer.zendesk.com/api-reference/ticketing/tickets/ticket-requests/#request-comments
export type RequestComment = {
  id?: number
  type?: COMMENT_TYPE
  request_id?: number
  body?: string
  html_body?: string
  plain_body?: string
  public?: boolean
  author_id?: number
  attachments?: Attachment[]
  uploads?: string[]
  created_at?: string
}

export type CustomField = {
  id: number
  value: string | boolean | number
}

export type Via = {
  channel: string
  source: {
    from: {
      name?: string
      address?: string
    }
    to: {
      name?: string
      address?: string
    }
    rel?: string
  }
}

export type Request = {
  assignee_id?: number
  can_be_solved_by_me?: boolean
  collaborator_ids?: number[]
  created_at?: string
  custom_fields?: CustomField[]
  custom_status_id?: number
  description?: string
  due_at?: string
  email_cc_ids?: number[]
  followup_source_id?: number
  group_id?: number
  id?: number
  is_public?: boolean
  organization_id?: number
  priority?: REQUESTS_PRIORITY
  recipient?: string
  requester_id?: number
  solved?: boolean
  status?: REQUESTS_STATUS
  subject: string
  ticket_form_id?: number
  type?: string
  updated_at?: string
  url?: string
  via?: Via
}

export type Collaborators = (number | string | Record<string, string>)[]

// https://developer.zendesk.com/documentation/ticketing/managing-tickets/creating-and-managing-requests/#creating-anonymous-requests
export type Requester = {
  name: string
  email?: string
  locale?: string
  locale_id?: string
}

// https://developer.zendesk.com/api-reference/ticketing/organizations/organizations/#json-format
export type Organization = {
  create_at?: string
  details?: string
  domain_names?: string[]
  external_id?: string
  group_id?: number
  id?: number
  name?: string
  notes?: string
  organization_fields?: Record<string, number | string>
  shared_comments?: boolean
  shared_tickets?: boolean
  tags?: string[]
  updated_at?: string
  url?: string
}

export type RequestUser = {
  id?: number
  name?: string
  photo?: Attachment
  agent?: boolean
  organization_id?: number
}
