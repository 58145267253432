import Lottie from 'react-lottie-player'

import spinnerBlue from './assets/tmds_circular_spin.json'
import spinnerGray from './assets/tmds_circular_spin_gray300.json'

interface CircularSpinnerProps {
  color?: 'blue' | 'gray';
  size?: number;
}

function CircularSpinner({ color = 'gray', size = 24 }: CircularSpinnerProps) {
  return (
    <Lottie
      loop
      animationData={color === 'blue' ? spinnerBlue : spinnerGray}
      play
      style={{
        width: size,
        height: size,
      }}
    />
  )
}

export default CircularSpinner
