import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { ROUTER } from '@/constants/router'
import { sendEvent } from '@/libs/mixpanel'
import { COLOR } from '@/styles/color'

import Section from './shared/components/Section'

function EtcSection() {
  const router = useRouter()

  const handleClickLinkButton = useCallback(() => {
    sendEvent(ROUTER.MAIN, 'tap.cs')

    router.push({
      pathname: ROUTER.QNA.INQUIRY,
      query: { isSkipPreview: false },
    })
  }, [router])

  return (
    <Section title="찾으시는 내용이 없나요?">
      <LinkBlock>
        <Button
          type="button"
          onClick={handleClickLinkButton}
        >
          1:1문의하기
        </Button>
      </LinkBlock>
    </Section>
  )
}

const LinkBlock = styled.div`
  padding-top: 20px;
`

const Button = styled.button`
  display: block;
  padding: 13px;
  width: 100%;
  border: 1px solid ${COLOR.primary.color.tmobi.blue[400]};
  border-radius: 6px;
  font-weight: var(--font-weight-bold);
  color: ${COLOR.primary.color.tmobi.blue[500]};
  text-align: center;
`

export default EtcSection
