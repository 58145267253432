import styled from '@emotion/styled'
import { forwardRef } from 'react'

import CircularSpinner from '@/components/loading/CircularSpinner'

interface ListLoadingProps {
  isLoading: boolean
}

const ListLoading = forwardRef<HTMLDivElement, ListLoadingProps>(({ isLoading }, ref) => {
  return (
    <Loading ref={ref}>
      {isLoading && <CircularSpinner />}
    </Loading>
  )
})

ListLoading.displayName = 'ListLoading'

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 32px;
`

export default ListLoading
