import { css } from '@emotion/react'
import styled from '@emotion/styled'

export default styled.div<{ width?: number | string; height: number; borderRadius?: string }>`
  width: ${({ width = '100%' }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) => height}px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.04));
  animation: blink 2s ease-in-out infinite;
  transition: opacity ease-in-out 0.3s;

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}

  @keyframes blink {
    50% {
      opacity: 0.3;
    }
  }
`
