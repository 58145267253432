import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useCallback, useMemo } from 'react'

import { getSearchArticles } from '@/apis/frontman/helpcenter/getSearchArticles'
import { ArticleItem } from '@/apis/frontman/helpcenter/types'
import SkeletonItem from '@/components/loading/SkeletonItem'
import { QUERY_KEY } from '@/constants/key'
import { LOG_PAGE_ID } from '@/constants/log'
import ArticleList from '@/features/shared/components/ArticleList'
import useFaqCategory from '@/hooks/useFaqCategory'
import { sendEvent } from '@/libs/mixpanel'

import Section from './shared/components/Section'

function BestFaqSection() {
  const { getCategoryNames } = useFaqCategory()
  const { data, status } = useQuery({
    queryKey: [QUERY_KEY.BEST_FAQ],
    queryFn: () => getSearchArticles({
      page: 1,
      per_page: 10,
      sort_by: 'updated_at',
      sort_order: 'desc',
      label_names: TmapApp.env.isIOS ? 'best_i_os' : 'best_a_nd',
    }),
  })

  const isLoading = useMemo(() => !['error', 'success'].includes(status), [status])

  const handleClickFaqItem = useCallback(({ id, title, section_id }: ArticleItem) => {
    let category_name = ''
    let section_name = ''
    if (section_id) {
      const [categoryName, sectionName] = getCategoryNames(section_id)
      category_name = categoryName
      section_name = sectionName ?? ''
    }

    sendEvent(LOG_PAGE_ID.MAIN, 'tap.best', {
      category_name,
      section_name,
      article_name: title,
      article_id: id,
    })
  }, [getCategoryNames])

  return (
    <Section
      title="자주 묻는 질문 BEST 10"
      fullWidth
    >
      {isLoading ? <Skeleton /> : (
        <ArticleList
          data={data?.results}
          onClickArticle={handleClickFaqItem}
        />
      )}
    </Section>
  )
}

function Skeleton({ size = 8 }: { size?: number }) {
  return (
    <SkeletonWrapper>
      {[...Array(size)].map((_, index) => (
        <SkeletonBlock key={`BestFaq-SkeletonBlock${index}`}>
          <SkeletonItem
            height={24}
            borderRadius="4px"
          />
          <SkeletonItem
            width="calc(100% - 28px)"
            height={24}
            borderRadius="4px"
          />
        </SkeletonBlock>
      ))}
    </SkeletonWrapper>
  )
}

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding: 18px 20px 0;
`

const SkeletonBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export default BestFaqSection
