import styled from '@emotion/styled'
import { useCallback } from 'react'

import { SearchArticleItem } from '@/apis/frontman/helpcenter/getSearchArticles'
import { ArticleItem } from '@/apis/frontman/helpcenter/types'
import { LOG_PAGE_ID } from '@/constants/log'
import ArticleList from '@/features/shared/components/ArticleList'
import useFaqCategory from '@/hooks/useFaqCategory'
import { sendEvent } from '@/libs/mixpanel'
import { COLOR } from '@/styles/color'
import { fontSize } from '@/styles/mixin'

import NotFoundSection from './NotFoundSection'

interface SearchContentProps {
  count?: number
  data?: SearchArticleItem[]
}

function SearchContent({ count, data }: SearchContentProps) {
  const { getCategoryNames } = useFaqCategory()

  const handleClickArticle = useCallback(({ id, title, section_id }: ArticleItem) => {
    let category_name = ''
    let section_name = ''
    if (section_id) {
      const [categoryName, sectionName] = getCategoryNames(section_id)
      category_name = categoryName
      section_name = sectionName ?? ''
    }
    sendEvent(LOG_PAGE_ID.SEARCH, 'tap.article', {
      category_name,
      section_name,
      article_name: title,
      article_id: id,
    })
  }, [getCategoryNames])

  if (data && !data.length) {
    return <NotFoundSection />
  }

  return (
    <ArticleSection>
      {data && <Total>{count ?? 0}개 결과가 있어요.</Total>}
      <ArticleList
        data={data}
        visibleCategory
        onClickArticle={handleClickArticle}
      />
    </ArticleSection>
  )
}

const ArticleSection = styled.div`
  padding-top: 16px;
`

const Total = styled.div`
  padding: 0 20px;
  color: ${COLOR.gray.color.gray[600]};
  ${({ theme }) => fontSize(theme, 14)}
`

export default SearchContent
