import { GetServerSidePropsContext } from 'next'

import { ROUTER } from '@/constants/router'
import HomePage from '@/features/home/HomePage'
import { getEncodeJsonObject } from '@/utils/parse'

export const getServerSideProps = async ({ query }: GetServerSidePropsContext) => {
  const ak = query.ak as string
  const extra = JSON.parse(
    query.extra && !!query.extra.length ? decodeURIComponent(query.extra as string) : '{}'
  )

  // Redirect URL: https://tmobi.atlassian.net/wiki/spaces/TMAPWEB/pages/594214914/URL
  let queryParams: Record<string, string> = {}
  const defaultQueryString: Record<string, string> = {
    isFirstHistory: 'true',
  }
  let queryString = ''

  if (ak) {
    defaultQueryString.ak = ak
  }

  switch (query.pageid) {
    case 'faq':
      // 카테고리별 FAQ 목록
      if (extra.categoryName || extra.categoryId) {
        queryParams = extra.categoryName
          ? { categoryName: extra.categoryName }
          : { categoryId: extra.categoryId }
        if (extra.sectionName) {
          queryParams.sectionName = extra.sectionName
        }
        if (extra.sectionId) {
          queryParams.sectionId = extra.sectionId
        }
        queryString = `?${new URLSearchParams({ ...queryParams, ...defaultQueryString }).toString()}`
        return {
          redirect: {
            permanent: false,
            destination: `${ROUTER.CATEGORY}${queryString}`,
          },
          props: {},
        }
      }
      break
    // 검색
    case 'faq.view':
      // FAQ 상세
      if (extra.id) {
        queryString = `?${new URLSearchParams({ ...defaultQueryString }).toString()}`
        return {
          redirect: {
            permanent: false,
            destination: `${ROUTER.FAQ(extra.id)}${queryString}`,
          },
          props: {},
        }
      }
      break
    case 'search':
      queryParams = { keyword: extra.keyword ?? '' }
      queryString = `?${new URLSearchParams({ ...queryParams, ...defaultQueryString }).toString()}`
      return {
        redirect: {
          permanent: false,
          destination: `${ROUTER.MAIN}${queryString}`,
        },
        props: {},
      }
    // 문의하기
    case 'qna':
      // category & section
      if (extra.categoryName) {
        queryParams.categoryName = extra.categoryName
        if (extra.sectionName) {
          queryParams.sectionName = extra.sectionName
        }
      }
      // action(=After Action)
      if (extra.action) {
        queryParams.action = getEncodeJsonObject(extra.action)
      }

      // 추가정보(additional) 값 전달
      if (extra.additional) {
        queryParams.additional =
          typeof extra.additional === 'object'
            ? getEncodeJsonObject(extra.additional)
            : encodeURIComponent(extra.additional)
      }

      if (extra.isSkipPreview === 'false') {
        queryParams.isSkipPreview = 'false'
      } else {
        queryParams.isSkipPreview = 'true'
      }

      if (Object.keys(queryParams).length) {
        queryString = `?${new URLSearchParams({ ...queryParams, ...defaultQueryString }).toString()}`
      } else {
        queryString = `?${new URLSearchParams({ ...defaultQueryString }).toString()}`
      }

      return {
        redirect: {
          permanent: false,
          destination: `${ROUTER.QNA.INQUIRY}${queryString}`,
        },
        props: {},
      }
    // 문의내역
    case 'qna.list':
      queryString = `?${new URLSearchParams({ ...defaultQueryString }).toString()}`
      return {
        redirect: {
          permanent: false,
          destination: `${ROUTER.QNA.LIST}${queryString}`,
        },
        props: {},
      }
    // 문의상세
    case 'qna.view':
      if (extra.id) {
        if (extra.gallery !== undefined) {
          queryParams = { gallery: extra.gallery }
          if (extra.slide) {
            queryParams.slide = extra.slide
          }
        }
        if (Object.keys(queryParams).length) {
          queryString = `?${new URLSearchParams({ ...queryParams, ...defaultQueryString }).toString()}`
        } else {
          queryString = `?${new URLSearchParams({ ...defaultQueryString }).toString()}`
        }
        return {
          redirect: {
            permanent: false,
            destination: `${ROUTER.QNA.DETAIL(extra.id)}${queryString}`,
          },
          props: {},
        }
      }
      queryString = `?${new URLSearchParams({ ...defaultQueryString }).toString()}`
      return {
        redirect: {
          permanent: false,
          destination: `${ROUTER.QNA.LIST}${queryString}`,
        },
        props: {},
      }
    default:
      break
  }

  return {
    props: {},
  }
}

export default HomePage
